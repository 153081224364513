<template>
  <VueQRCodeComponent :text="value" :size="size" :error-level="level" />
</template>

<script>
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  name: 'QrCode',
  components: { VueQRCodeComponent },
  props: {
    level: { type: String, default: 'M' },
    size: { type: Number, default: 175 },
    value: { type: String, required: true },
  },
}
</script>
